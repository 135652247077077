import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonNova } from "../../../../../nutrafol-ui-kit/src/ButtonNova"
import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"

import { isIpad } from "../../../../utils/general"

import RightArrowSubMenu from "../../../../assets/icons/nova/arrow-right-solid-40.svg"

const label = `See All Products`

const SubItemWrapper = styled(Box)`
  width: 100%;
`
const SubItem = styled(Box)``

const DropdownMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 72px;
  background: transparent;
  z-index: 12;
  transition:
    visibility 0s,
    opacity 0.1s ease-in-out;
  left: -1px;
  width: 206px;

  border: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-radius: 2px;

  max-width: 1037px !important;
  .shop-items {
    width: 38% !important;
    min-width: 372px;
    margin-right: 40px !important;
    @media (min-width: 1080px) {
      margin-right: 64px !important;
    }
  }
  .shop-sub-items {
    max-width: 241px !important;
  }
  .shop-service-items {
    max-width: 214px;
    margin-left: 40px !important;
    @media (min-width: 1080px) {
      margin-left: 64px !important;
    }
  }

  .row {
    background-color: ${theme.wild.color.nova.base.white} !important;
    .box-left {
      width: 100% !important;
    }
  }

  &.dropdown-shop-desktop {
    left: -1px;
    width: 100%;
    transform: none;

    .row {
      .box-left {
        width: 445px;
        @media (min-width: 1280px) {
          width: 553px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    top: 64px;
  }
`
const DropdownWrapper = styled(Flex)`
  background: ${theme.wild.color.nova.base.white};

  box-shadow:
    0px 1.75px 2.31px 0px #00000002,
    0px 8px 6.5px 0px #00000004,
    0px 20.25px 17.44px 0px #00000005,
    0px 40px 40px 0px #00000008;

  padding: 40px 40px;
  flex-direction: row;
`
const MainBox = styled(Box)`
  .shop-items,
  .shop-service-items,
  .shop-sub-items {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .shop-items {
    width: 51%;
    border-radius: 4px;
    background-color: ${theme.wild.color.nova.base.solid[20]};
  }
`
const NavItem = styled.div`
  a {
    display: block;
    padding: 16px 32px;
  }

  &.services-nav-item,
  &.shop-nav-item {
    svg {
      opacity: 0;

      path {
      }
    }

    h4 {
      text-decoration: none;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
`
const SubNavItem = styled.div`
  a {
    display: block;
    padding: 8px 16px;
  }

  &.sub-service-nav-item,
  &.sub-shop-nav-item {
    svg {
      opacity: 0;
    }

    h4 {
      text-decoration: none;
    }

    &:hover {
      background: ${theme.wild.color.nova.base.solid[20]};
      svg {
        opacity: 1;
      }
    }
  }
`
const ButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[
      ...document.querySelectorAll("nav.navigation-variant [aria-expanded]"),
    ].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")

const buildCls = (subitem) => {
  return subitem.subtext ? "has-subtext" : "no-subtext"
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

//force navigate for ipad https://nutrafol.atlassian.net/browse/NT2-8512
const onTouchStart = (url, state) => {
  if (isIpad()) {
    navigate(url, { state })
  }
}

const NavigationShopMenu = ({ data }) => {
  const [hoveredItem, setHoveredItem] = useState(null)

  const item = data[0]

  return (
    <DropdownMenu className={`dropdown dropdown-shop-desktop`}>
      <DropdownWrapper className="row">
        <Box className={`box-left`}>
          <MainBox>
            {item.children && (
              <Flex>
                <Box className={"shop-items"} mr={30}>
                  <Flex flexWrap="wrap" flexDirection={"column"}>
                    <Box pl={[32]} pb={[32]}>
                      <TextElementNova
                        element="h3"
                        text={item.childrenTitle}
                        className="Subtitle-10-Dia-Normal"
                      />
                    </Box>
                    {item.children.map((subitem, idx) => (
                      <Box
                        width={1}
                        key={`nav-item-${idx}`}
                        className={subitem.name}
                      >
                        <NavItem
                          onTouchStart={() => onTouchStart(subitem.url)}
                          className={`${item.name}-nav-item hover:bg-[#fff]`}
                        >
                          <Link
                            to={subitem.url}
                            key={`subitem-${idx}`}
                            onClick={() => {
                              trackClicked(subitem.name, subitem.url)
                            }}
                          >
                            <SubItemWrapper
                              onMouseEnter={() => setHoveredItem(subitem.name)}
                              onMouseLeave={() => setHoveredItem(null)}
                              onClick={removeActiveItemClass}
                            >
                              <SubItem
                                className={`${subitem.name.toLowerCase()} ${buildCls(
                                  subitem,
                                  hoveredItem
                                )} group ${hoveredItem ? "hovered" : ""}`}
                              >
                                <Flex
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Box>
                                    <TextElementNova
                                      element="h4"
                                      text={subitem.name}
                                      className={
                                        "Body-20-Dia-Strong text-nova-brand-primary-100"
                                      }
                                      /*
                                       * styles={{
                                       *   color: subitem.isMens
                                       *     ? `#0F3956`
                                       *     : theme.wild.color.nova.brand
                                       *         .primary[80],
                                       * }}
                                       */
                                    />
                                    <TextElementNova
                                      element="p"
                                      text={subitem.subtextDesktop}
                                      className="Body-10-Dia-Normal"
                                      styles={{
                                        color: theme.wild.color.nova.base.black,
                                      }}
                                    />
                                  </Box>
                                  <RightArrowSubMenu />
                                </Flex>
                              </SubItem>
                            </SubItemWrapper>
                          </Link>
                        </NavItem>
                      </Box>
                    ))}
                  </Flex>
                </Box>
                {item.subCategories && (
                  <Box className={"shop-sub-items"} width={"49%"}>
                    <Flex flexDirection="column">
                      <Box pl={[16]} pb={[20]}>
                        <TextElementNova
                          element="h3"
                          text={item.subCategoriesTitle}
                          className="Body-30-Dia-Normal"
                        />
                      </Box>
                      {item.subCategories.map((subCategory, idx) => (
                        <Box key={`sub-nav-item-${idx}`}>
                          <SubNavItem
                            onTouchStart={() =>
                              onTouchStart(subCategory.url, {
                                anchor: subCategory.anchor,
                              })
                            }
                            className={`sub-${item.name}-nav-item`}
                          >
                            <Link
                              to={subCategory.url}
                              key={`subcategory-${idx}`}
                              onClick={() => {
                                trackClicked(subCategory.name, subCategory.url)
                              }}
                              state={{ anchor: subCategory.anchor }}
                            >
                              <SubItemWrapper
                                onMouseEnter={() =>
                                  setHoveredItem(subCategory.name)
                                }
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={removeActiveItemClass}
                              >
                                <SubItem
                                  className={`${subCategory.name.toLowerCase()} ${buildCls(
                                    subCategory,
                                    hoveredItem
                                  )}`}
                                >
                                  <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <TextElementNova
                                      element="h4"
                                      text={subCategory.name}
                                      className="Body-20-Dia-Strong"
                                    />
                                    <RightArrowSubMenu />
                                  </Flex>
                                </SubItem>
                              </SubItemWrapper>
                            </Link>
                          </SubNavItem>
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                )}
                <Box
                  className="shop-service-items growthplan-plus-abtest-variant"
                  ml={40}
                >
                  <Flex flexDirection="column">
                    <Box pl={[16]} pb={[20]}>
                      <TextElementNova
                        element="h3"
                        text={item.servicesTitle}
                        className="Body-30-Dia-Normal"
                      />
                    </Box>
                    {item.services.map((service, idx) => (
                      <Box
                        width={1}
                        key={`service-item-${idx}`}
                        className={service.name}
                      >
                        <SubNavItem
                          onTouchStart={() => onTouchStart(service.url)}
                          className={`sub-service-nav-item hover:bg-[#e8faf7]`}
                        >
                          <Link
                            to={service.url}
                            key={`serviceitem-${idx}`}
                            onClick={() => {
                              trackClicked(service.name, service.url)
                            }}
                          >
                            <SubItemWrapper
                              onMouseEnter={() => setHoveredItem(service.name)}
                              onMouseLeave={() => setHoveredItem(null)}
                              onClick={removeActiveItemClass}
                            >
                              <SubItem
                                className={`${service.name.toLowerCase()} ${buildCls(
                                  service,
                                  hoveredItem
                                )} group ${hoveredItem ? "hovered" : ""}`}
                              >
                                <Flex
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Box>
                                    <TextElementNova
                                      element="h4"
                                      text={service.name}
                                      className={
                                        "Body-20-Dia-Strong text-nova-base-black"
                                      }
                                    />
                                    <TextElementNova
                                      element="p"
                                      text={service.subtext}
                                      className="Body-10-Dia-Normal text-nova-transparency-black-70 pt-1"
                                    />
                                  </Box>
                                  <Box>
                                    <RightArrowSubMenu />
                                  </Box>
                                </Flex>
                              </SubItem>
                            </SubItemWrapper>
                          </Link>
                        </SubNavItem>
                      </Box>
                    ))}
                  </Flex>
                </Box>
              </Flex>
            )}
          </MainBox>
          <Box pt={[40]}>
            <ButtonWrapper>
              <ButtonNova
                width={`100%`}
                icon={`Right`}
                label={label}
                className="desktop-button"
                onClick={() => {
                  trackClicked(label, "/products/")
                  navigate("/products/")
                }}
              />
            </ButtonWrapper>
          </Box>
        </Box>
      </DropdownWrapper>
    </DropdownMenu>
  )
}

export default NavigationShopMenu
